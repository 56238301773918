body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .CalendarDay__selected_span:active, .CalendarDay__selected_span:hover, .CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
    background: #bc0a05;
    border: 1px double #bc0a05;
    color: #fff;
}

.CalendarDay__selected_span {
    background: #f4c92e;
    border: 1px double #f3e1a1;
    color: #000;
}

button.fullHeighted {height: 56px;}

.CalendarDay__blocked_out_of_range.CalendarDay__blocked_calendar, .CalendarDay__blocked_out_of_range.CalendarDay__blocked_calendar:active, .CalendarDay__blocked_out_of_range.CalendarDay__blocked_calendar:hover {
    background: #cacccd;
    border: 1px solid #cacccd;
    color: #82888a;
}